import styled from 'styled-components';
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


export const appStyle = {

    background: '#424238',
    background2: '#484850',
    background3: '#909090',
    darkGreen: '#102400',
    midDarkGreen: '#3D6215',
    midGreen: '#169179',
    midLightGreen: '#BCE072',
    
    czubajHighLight: '#bbb',//'#FFE5C4',
    czubajLight: '#aaa',//'#FFF8e0',
    czubajMid: '#999',//'#FFE1A8',
    
    czubaj2Light: '#bbb', // background,
    czubaj2MidLight: '#aaa',
    czubaj2Mid: '#999',
    
    mietowy: '#999', //'#F8FFE2',
    mietowyLight: '#999', //'#FBFFF0',
    
    czubajGradient: 'linear-gradient(to bottom, #999,  #999)', //deactivated
    czubajRevGradient: 'linear-gradient(to bottom, #999,  #999)', //deactivated
    greenGradient: 'linear-gradient(to right, #BCE072, #3D6215, #102400',
    appRadius: '0.4rem',
    titleFontSize:'22px'
}



//     overflow:hidden; /* prevent to text in column overflow beyond element */
// width: fit-content;
export const SCol = styled(Col)`

    display: inline-flex;
    flex-direction: column; 
    paddingLeft: 0;
    paddingRight: 0;
 
`


  // display: block;
    //  display: inline-flex;
    // flex-direction: column; 
   //  width: fit-content;   



export const SRow = styled(Row)`

    margin-left:0;
    margin-right:0;
    margin-top: 0.24rem;
    margin-bottom: 0;
`


export const SInputContainer = styled.div`
    width: 24rem;
    hight: 1rem;
`;


export const SInputFormControl = styled(Form.Control)`

    height: 26px;
    // border:  solid 2px ${appStyle.midLightGreen};
    border-radius: 0px;
    margin: 0;
    padding-left: 0.24rem;
    padding-right: 0.24rem;
    
    
    color: ${appStyle.midDarkGreen};
    background-color: rgba(0,0,0,0.0);
    opacity: 0.0;
    position: relative;

    box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);

    :focus {
        /*background-color: ${appStyle.czubajHighLight};*/
    }
    &.active {
        
        /*background-color: ${appStyle.czubajHighLight};*/
        color: #000000; /*AE674E*/        
    }

    :hover {
  
        /*background-color: ${appStyle.czubajLight};*/
        color: #000000;
    } 

    &::-webkit-input-placeholder {
      color: "white";
    }
`;    




export const SButton = styled(Button)`

    height: 24px;
    padding-top: 0px;
    margin-top: 0px;
    margin-buttom: 0px;
    margin-right: 4px;
    background-color: ${appStyle.midDarkGreen};
    border: 2px solid ${appStyle.midLightGreen};

    :hover {
        color: black;
        background-color: ${appStyle.czubajLight};  
        border: 2px solid ${appStyle.midLightGreen};
        box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
    }

    transition: background-color 0.3s ease-in;
`;


// padding: 0px;
// margin: 0px;
// background-color: ${appStyle.midDarkGreen};
// border:  solid 2px ${appStyle.midDarkGreen};
// box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
export const STabs = styled(Tabs)`

    width: 100%;
    flex-wrap: nowrap;
    overflow: hidden;

    background-color: rgb(0,0,0,0.0);
    height: 26px;
    color: ${appStyle.midDarkGreen};

    margin: 0;
    padding: 0;
    opacity: 1.0;
    position: relative;
`;

export const STab = styled(Tab)`

    width: -webkit-fill-available;
    height: 26px;
    margin: 0;
    padding:0;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    :hover {

        background-color: ${appStyle.czubajLight}; 
        color: #000000;
    } 
`;





/*
export const SParentListGroupItem = styled(ListGroup.Item)`
    font-family: "Patric Hand";
    font-weight: normal;
    font-size: ${titleFontSize};
    display: inline-flex;
    flex-direction: row;
    width: max-content;

    opacity: 1.0;
    position: relative;

    transition: .3s all;

    margin: 0px;
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 0rem 1rem;

    box-shadow: 2px 2px 10px rgba(0.5, 0.7, 0.9, 0.5);
    border:  solid 2px ${appStyle.midLightGreen};
    border-radius: 0px;
    color: white; 
    background-color: rgba(0,0,0,0.0);

    cursor: pointer;
    &.active {
        background-color: rgba(0,0,0,0.0);
        color: white;    
        border:  solid 2px ${appStyle.midLightGreen};
        box-shadow: none;
        transform: scale(0.9);
    }

    :hover {
  
        color: #40e040;
        border: 2px solid #ffffff;
        box-shadow: 4px 4px 22px 0px rgba(255,255,0,0.17);
    } 
`;
*/
